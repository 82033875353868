import { AxiosError } from 'axios';
import axios from '../../utils/axios';
import { DEFAULT_ERROR_MESSAGE, MediaType } from '../../utils/constants';
import { SignedUrlData } from './types';

export interface UploadToGCS {
  url: string;
  file: any;
  metadata?: any;
  onLoad?: (responseText: string) => void;
  onError?: (message: string) => void;
  onProgress?: (lengthComputable: boolean, loaded: number, total: number) => void;
  onAbort?: () => void;
}

export const uploadToGCS = async ({ url, file, onLoad, onError, onProgress, onAbort }: UploadToGCS) => {
  const binaryData = await file.arrayBuffer();
  const request = new XMLHttpRequest();
  request.open('PUT', url);
  request.setRequestHeader('Content-Type', file['type']);

  request.upload.onprogress = (e) => {
    if (onProgress) onProgress(e.lengthComputable, e.loaded, e.total);
  };

  request.onload = function () {
    if (request.status >= 200 && request.status < 300 && onLoad) {
      onLoad(request.responseText);
    } else if (onError) {
      onError(`Error: ${request.status} - ${request.statusText}`);
    }
  };

  request.send(binaryData);

  return {
    abort: () => {
      request.abort();
      if (onAbort) onAbort();
    },
  };
};

export const generateSignedUrl = async (media: any[]): Promise<SignedUrlData> => {
  const mediaType = determineMediaType(media[0]['type']);
  const mediaName = whiteSpaceRemover(media[0]['name']);
  try {
    const params = {
      bucket: process.env.REACT_APP_GOOGLE_CLOUD_URL,
      filename: `${mediaType}_Uploads/${mediaName}`,
    };
    const res = await axios.get('/media/signed-url', {
      params: params,
    });
    return {
      url: res.data.url,
      expiry: res.data.expiry,
    };
  } catch (err) {
    let errorMessage: string;
    if (err instanceof AxiosError) {
      errorMessage = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
    } else {
      errorMessage = err.message;
    }
    throw Error(errorMessage);
  }
};

export const determineMediaType = (mediatype: string): MediaType => {
  if (mediatype.includes('image')) {
    return MediaType.IMAGE;
  } else if (mediatype.includes('video')) {
    return MediaType.VIDEO;
  } else {
    return MediaType.DOCUMENT;
  }
};

export const extractCloudUrl = (url: string): string => {
  const match = url.match(/(https:\/\/storage\.googleapis\.com\/studyitlive_website_images\/[^?]*)/);
  return match ? match[0] : '';
};

const whiteSpaceRemover = (name: string): string => {
  return name.replace(/\s+/g, '');
};
