import React, { useEffect, useState } from 'react';
import { ProfileData } from '../types';
import axios from '../utils/axios';
import { showErrorToast } from '../utils/errorHandler';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProfilePicture } from './ProfilePicture';
import { AxiosError } from 'axios';
import { Alert } from 'flowbite-react';
import { ImageUploader } from '.';
import { ToastContainer, toast } from 'react-toastify';
import { UploadResponse } from '../components/UploadComponent/types';
import { DEFAULT_ERROR_MESSAGE } from '../utils/constants';

const Profile = () => {
  const [editable, setEditable] = useState(false);
  const [imageUploaderState, setImageUploaderState] = useState({
    show: false,
    loading: false,
  });
  const showProfileEditToast = () =>
    toast.success('Profile Edited Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'ProfileToast',
    });
  const queryClient = useQueryClient();
  const { mutate: mutateProfileData } = useMutation({
    mutationFn: async (profile: ProfileData) => {
      setError('');
      return await axios.put(`/users/me`, {
        bio: profile.bio,
        title: profile.title,
        firstName: profile.firstName,
        lastName: profile.lastName,
        profilePhotoUrl: profile.profilePhotoUrl,
      });
    },
    mutationKey: ['profile'],
    onSuccess: () => {
      showProfileEditToast();
      setEditable(false);
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
    onError: (error) => {
      showErrorToast();
    },
  });

  const [profile, setProfile] = useState<ProfileData>({
    bio: '',
    email: '',
    firstName: '',
    lastName: '',
    roles: [],
    title: '',
    profilePhotoUrl: '',
  });
  const [error, setError] = useState('');

  const showImageUploader = () => {
    setImageUploaderState({ ...imageUploaderState, show: true });
  };

  const closeImageUploader = () => {
    setImageUploaderState({ ...imageUploaderState, show: false });
  };

  const finalizeUpload = async (cloudObj: UploadResponse) => {
    setImageUploaderState({ ...imageUploaderState, loading: true });
    const pictureUrl = cloudObj.url;
    mutateProfileData({ ...profile, profilePhotoUrl: pictureUrl });
    setImageUploaderState({ ...imageUploaderState, loading: false });
  };

  const deleteProfilePicture = async () => {
    mutateProfileData({
      ...profile,
      profilePhotoUrl: '',
    });
  };

  const handleChange = (e: any) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const saveProfileChanges = async (event: any) => {
    event.preventDefault();
    mutateProfileData(profile);
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await axios.get(`/users/me`);
        const nonNullFields = Object.entries(res.data)
          .filter(([k, v]) => !!v)
          .reduce((acc: any, x) => {
            acc[x[0]] = x[1];
            return acc;
          }, {} as ProfileData);
        setProfile({ ...profile, ...nonNullFields });
      } catch (error) {
        if (error instanceof AxiosError) {
          const errorMessage: string = error.response ? error.response.data.message : DEFAULT_ERROR_MESSAGE;
          setError(errorMessage);
        } else {
          setError(error.message);
        }
      }
    };

    getProfile();
  }, []);

  return (
    <div className="w-100 lg:w-5/6">
      {error && (
        <Alert color="failure" className="mb-4">
          <p className="font-medium font-lato">{error}</p>
        </Alert>
      )}
      <div className="flex flex-col items-center justify-center pt-4">
        <img src={ProfilePicture()} className="rounded-full profile-picture" />
        <p className="font-lato text-xs font-semibold mb-0 pt-3.5 pb-2 text-gray-400">Edit Profile Picture</p>
        <div className="flex flex-row">
          <p
            className="mb-0 text-base font-light font-lato text-primary90 me-2 custom-pointer"
            onClick={showImageUploader}
          >
            Edit
          </p>
          <p
            className="mb-0 text-base font-light text-red-500 font-lato custom-pointer"
            onClick={() => deleteProfilePicture()}
          >
            Delete
          </p>
        </div>
      </div>
      <div>
        {profile && (
          <form>
            <div className="grid gap-4 pb-6 lg:grid-cols-9">
              <div className="lg:col-span-1">
                <label htmlFor="title" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={profile.title}
                  onChange={handleChange}
                  className={`form-input-fields border border-slate-500 font-lato font-medium font-xl ${
                    !editable ? 'bg-gray-200' : ''
                  }`}
                  disabled={!editable}
                />
              </div>
              <div className="lg:col-span-4">
                <label htmlFor="firstName" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={profile.firstName}
                  onChange={handleChange}
                  className={`form-input-fields border border-slate-500 font-lato font-medium font-xl ${
                    !editable ? 'bg-gray-200' : ''
                  }`}
                  disabled={!editable}
                />
              </div>
              <div className="lg:col-span-4">
                <label htmlFor="lastName" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={profile.lastName}
                  onChange={handleChange}
                  className={`form-input-fields border border-slate-500 font-lato font-medium font-xl ${
                    !editable ? 'bg-gray-200' : ''
                  }`}
                  disabled={!editable}
                />
              </div>
            </div>

            <div className="grid gap-4 pb-6 lg:grid-cols-9">
              <div className="lg:col-span-5">
                <label htmlFor="email" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={profile.email}
                  onChange={handleChange}
                  className="font-medium bg-gray-200 border form-input-fields border-slate-500 font-lato font-xl"
                  disabled
                />
              </div>
            </div>

            <label htmlFor="description" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
              Bio
            </label>
            <textarea
              name="bio"
              id="bio"
              value={profile.bio}
              onChange={handleChange}
              className={`textarea-input-fields border border-slate-500 mb-6 font-lato font-medium font-xl ${
                !editable ? 'bg-gray-200' : ''
              }`}
              disabled={!editable}
            />
            {editable ? (
              <div className="flex">
                <button
                  className="h-12 font-bold rounded me-4 custom-secondary-btn font-poppins w-36"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditable(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="h-12 font-bold rounded custom-primary-btn font-poppins w-36"
                  onClick={saveProfileChanges}
                >
                  Save Profile
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="h-12 font-bold rounded custom-primary-btn font-poppins w-36"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditable(true);
                  }}
                >
                  Edit
                </button>
              </div>
            )}
          </form>
        )}
      </div>
      <ImageUploader
        imageName={profile.firstName}
        loading={imageUploaderState.loading}
        show={imageUploaderState.show}
        onHide={closeImageUploader}
        onSuccess={finalizeUpload}
        imageAspectRatio="1:1"
      />

      <ToastContainer containerId="ProfileToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};

export default Profile;
