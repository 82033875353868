import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { object, string, ValidationError } from 'yup';
import { Alert } from 'flowbite-react';
import FormCarousel from '../components/FormCarousel';
import axios from '../utils/axios';
import { AxiosError } from 'axios';
import { DEFAULT_ERROR_MESSAGE, imageUrls } from '../utils/constants';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState('');
  const [submissionErrors, setSubmissionErrors] = useState('');
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const validationSchema = object({
    email: string().required('Email Is Required').email('Invalid Email Format'),
  });
  const PostUserData = async (event: any) => {
    event.preventDefault();
    setSubmissionErrors('');
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      const res = await axios.post(`/auth/reset-request`, {
        email: email.toLowerCase(),
      });
      setIsSubmissionSuccessful(true);
    } catch (err) {
      if (err instanceof ValidationError) {
        setValidationErrors(err.inner[0].message);
      } else if (err instanceof AxiosError) {
        const errorMessage: string = err.response ? err.response.data.message : DEFAULT_ERROR_MESSAGE;
        setSubmissionErrors(errorMessage);
      } else {
        setSubmissionErrors(err.message);
      }
    }
  };

  return (
    <div className="grid lg:grid-cols-2 overflow-hidden">
      <div className="hidden lg:block">
        <FormCarousel />
      </div>

      <div className="flex justify-center items-center h-screen">
        <div className="w-10/12">
          <img src={imageUrls.SilLogo} alt="Studtitlive Logo" className="mx-auto pb-6 pt-2 lg:pt-0 lg:hidden d-block" />
          {!isSubmissionSuccessful ? (
            <>
              <h2 className="font-lato font-bold text-3xl text-center pb-2 capitalize">Forgot your Password?</h2>
              <h6 className="font-lato text-lg capitalize text-center pb-4">
                Remembered Your Password? &nbsp;
                <Link to="/login" className="text-decoration-none dark-blue-color font-lato font-semibold text-lg">
                  Login Now
                </Link>
              </h6>
              <div className="bg-white rounded drop-shadow-lg">
                <div className="p-6">
                  {submissionErrors && (
                    <Alert color="failure" className="mb-4">
                      <p className="font-medium font-lato">{submissionErrors}</p>
                    </Alert>
                  )}
                  <form>
                    <label htmlFor="email" className="block font-lato text-lg font-normal pb-2">
                      E-mail
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input-fields border border-slate-500"
                      required
                    ></input>
                    {validationErrors && <p className="text-red-600 font-lato text-base">{validationErrors}</p>}
                    <button
                      onClick={(e) => PostUserData(e)}
                      className="custom-primary-btn w-full h-12 rounded font-poppins text-xl font-medium mt-4"
                    >
                      Send Reset Link
                    </button>
                  </form>
                </div>
              </div>
            </>
          ) : (
            <div className="bg-primary rounded-md drop-shadow-lg">
              <div className="p-6 text-white">
                <h1 className="capitalize font-lato text-2xl font-bold pb-4 mb-0">password reset email sent</h1>
                <p className="capitalize font-lato font-normal text-lg pb-6 mb-0">
                  Success! A link has been sent to your email to reset your password. Please check your inbox and follow
                  the instructions to proceed
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PasswordReset;
