import { toast, ToastPosition } from 'react-toastify';
import { DEFAULT_ERROR_MESSAGE } from './constants';

export const showErrorToast = (
  errorMessage: string = DEFAULT_ERROR_MESSAGE,
  hideProgressBar: boolean = true,
  position: ToastPosition = 'top-right',
  autoClose: number = 3000
) =>
  toast.error(errorMessage, {
    position: position,
    autoClose: autoClose,
    className: 'font-lato text-base font-medium',
    hideProgressBar: hideProgressBar,
    theme: 'light',
    containerId: 'errorToast',
  });
