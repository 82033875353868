import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { Course, Section } from '../../types';
import { currencyFormater, verifyPaymentLink } from '../../utils/utilMethods';
import { showErrorToast } from '../../utils/errorHandler';
import { ToastContainer } from 'react-toastify';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants';

function StudentCourse() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [course, setCourse] = useState<Course>();
  const [sections, setSections] = useState<Section[]>([]);
  const [error, setError] = useState('');

  const submitPayment = async () => {
    setError('');
    try {
      const res = await axios.post(`/enrollment`, {
        courseId: courseId,
      });
      if (course?.price && course?.price > 0) {
        const linkIsValid = verifyPaymentLink(res.data.paymentLink);
        if (linkIsValid) {
          window.location.href = res.data.paymentLink;
        } else {
          setError('Sorry We Couldnt Generate A Payment Link At The Moment Please Try Again Later');
        }
      } else {
        navigate(`/student/course/${courseId}/view`);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      showErrorToast(errorMessage);
    }
  };

  const getCourse = async () => {
    try {
      const res = await axios.get(`/courses/${courseId}`);
      setCourse(res.data);
      setSections(res.data.sections);
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div className="page-card-container bg-white rounded shadow-sm">
      <div className="px-4 py-6 m-0 flex justify-between content-center">
        <h1 className="self-center font-lato text-black text-lg md:text-3xl font-bold mb-0 capitalize">
          {course?.title}
        </h1>
        <button className="self-center custom-primary-btn w-64 h-14 rounded" onClick={() => submitPayment()}>
          {course?.price && course?.price > 0 ? (
            <p className="font-poppins text-base font-medium mb-0">Pay {currencyFormater(course?.price)} To Enroll</p>
          ) : (
            <p className="font-poppins text-base font-medium mb-0">Free To Enroll</p>
          )}
        </button>
      </div>
      <div className="px-4 pb-6">
        <img src={course?.thumbnailUrl} alt="Course-Image" className="w-full h-[400px] rounded" />
        <div className="mb-0 pt-4">
          <p className="font-lato font-bold text-xl mb-2">Course Description</p>
          <p className="font-lato font-medium text-base text-gray-400 mb-4">{course?.description}</p>
          <p className="font-lato font-bold text-xl mb-2">Course Material</p>
          {sections.length >= 1 ? (
            <ul className="list-disc pl-6">
              {sections.map((section: Section, index: number) => (
                <li key={`section-${section._id}-column`}>
                  <p className="font-lato font-medium text-base text-gray-400">
                    Lesson {index + 1}: &nbsp;{section.title}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            ''
          )}
        </div>
      </div>
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
}
export default StudentCourse;
